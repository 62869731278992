.shadowCustom1 {
	filter: drop-shadow(0 0 0.1rem rgb(226, 210, 213))drop-shadow(0 0 0.1rem rgba(226, 210, 213, 0.596));
	
}
.logo {
	// width: 125px;
    // height: auto;
  border-radius: 10px;
}
.logoBackground {
	filter: drop-shadow(0 0 0.6rem rgb(226, 210, 213))drop-shadow(0 0 0.3rem rgba(226, 210, 213, 0.596));
}
@media (max-width: 768px) {
  
  /* Masaüstü boyutları için uygun genişlik ve yükseklik ayarları */
  .logo {
      width: 100px;
      height: auto;
      border-radius: 10px;
  }
  
}


